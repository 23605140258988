import React from "react";
import Lottie from "lottie-react";
import LoadingData from "./data.json";
import styles from "./styles.module.scss";
const Loading = ({ className = "" }) => {
  return (
    <div className={`${styles.loading} ${className}`}>
      <div className={styles.lottie}>
        <Lottie animationData={LoadingData} loop={true} />
      </div>
      {/* <div className="line-scale-pulse-out">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div> */}
    </div>
  );
};

export default Loading;
