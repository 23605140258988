import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import Loading from "@/components/Loading";
// 活动中心
const ActivityCenter = Loadable({
  loader: () => import("@/pages/activityCenter"),
  loading: () => <Loading />,
});
const Faq = Loadable({
  loader: () => import("@/pages/faq"),
  loading: () => <Loading />,
});
const Cat = Loadable({
  loader: () => import("@/pages/faq/cat"),
  loading: () => <Loading />,
});
const List = Loadable({
  loader: () => import("@/pages/list"),
  loading: () => <Loading />,
});

const Detail = Loadable({
  loader: () => import("@/pages/detail"),
  loading: () => <Loading />,
});

const NotFound = Loadable({
  loader: () => import("@/pages/notFound"),
  loading: () => <Loading />,
});

const Download = Loadable({
  loader: () => import("@/pages/download"),
  loading: () => <Loading />,
});

const SupportCenter = Loadable({
  loader: () => import("@/pages/supportCenter"),
  loading: () => <Loading />,
});

const SupportCenterList = Loadable({
  loader: () => import("@/pages/supportCenter/list"),
  loading: () => <Loading />,
});

const SupportCenterSearch = Loadable({
  loader: () => import("@/pages/supportCenter/search"),
  loading: () => <Loading />,
});

const SupportCenterDetail = Loadable({
  loader: () => import("@/pages/supportCenter/detail"),
  loading: () => <Loading />,
});

const Guide = Loadable({
  loader: () => import("@/pages/guide"),
  loading: () => <Loading />,
});

const MsgDetail = Loadable({
  loader: () => import("@/pages/msg"),
  loading: () => <Loading />,
});

const ListByCode = Loadable({
  loader: () => import("@/pages/listByCode"),
  loading: () => <Loading />,
});

const articleByCode = Loadable({
  loader: () => import("@/pages/articleByCode"),
  loading: () => <Loading />,
});

const userGuide = Loadable({
  loader: () => import("@/pages/userGuide"),
  loading: () => <Loading />,
});

const playVideo = Loadable({
  loader: () => import("@/pages/video"),
  loading: () => <Loading />,
});

const ListWithImage = Loadable({
  loader: () => import("@/pages/listWithImage"),
  loading: () => <Loading />,
});

const CategorySlides = Loadable({
  loader: () => import("@/pages/categorySlides"),
  loading: () => <Loading />,
});

const CallApp = Loadable({
  loader: () => import("@/pages/callApp"),
  loading: () => <Loading />,
});

const RootApp = () => (
  <Router>
    <Switch>
      <Route exact path="/" component={Download} />
      <Route path="/callapp" component={CallApp} />
      <Route path="/detail/:id" component={Detail} />
      <Route path="/msg/:id" component={MsgDetail} />
      <Route path="/list/:id" component={List} />
      <Route path="/activity-center" component={ActivityCenter} />
      <Route path="/download/*" component={Download} />
      <Route path="/faq/:id" component={Faq} />
      <Route path="/cat/:id" component={Cat} />
      <Route path="/:lang/article/:code" component={articleByCode} />
      <Route path="/support-center/list/:id" component={SupportCenterList} />
      <Route
        path="/support-center/detail/:id"
        component={SupportCenterDetail}
      />
      <Route path="/support-center/search" component={SupportCenterSearch} />
      <Route path="/support-center" component={SupportCenter} />
      <Route path="/:lang?/guide" component={Guide} />
      <Route path="/:lang?/list-by-code/:code" component={ListByCode} />
      <Route path="/:lang?/user-guide/:code" component={userGuide} />
      <Route path="/:lang?/video/:code" component={playVideo} />
      <Route path="/:lang?/list-with-image/:code" component={ListWithImage} />
      <Route path="/:lang?/category-slides/:code" component={CategorySlides} />
      <Route path="/:lang" component={Download} />
      <Route path="*" component={NotFound} />
    </Switch>
  </Router>
);

export default RootApp;
